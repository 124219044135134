import React, { useEffect, useState } from "react";
import { StylesProvider, jssPreset } from "@material-ui/styles";
import App from "./App";
import { create } from "jss";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";
import { CookiesProvider } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getTheme } from "./store/Theme/ThemeActions";
import { hexToRgba, setFontColorOnBg } from "./customHooks/useThemeHook";

const defaultThemeConfig = {
  spacing: 10,
  shape: {
    borderRadius: 10,
  },
  breakpoints: {
    values: {
      xs: 0,
      // sm: 710,
      sm: 830,
      md: 1024,
    },
  },
  overrides: {},
};

const ThemeApp = () => {
  const jss = create({ plugins: [...jssPreset().plugins] });
  const dispatch = useDispatch();
  const [btnTextColor, setBtnTextColor] = useState("#000000");
  const theme = useSelector((state) => state.theme);

  const convertRgbaToHex = (rgba) => {
    if (rgba.startsWith("#")) {
      return rgba;
    }

    // Remove the alpha part (first two characters)
    const hex = `#${rgba?.slice(2)}`;
    return hex;
  };

  const isLightColor = (hex) => {
    const rgb = parseInt(hex?.substring(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luminance > 128;
  };

  const adjustColorShade = (hex, percent) => {
    const num = parseInt(hex?.slice(1), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      G = ((num >> 8) & 0x00ff) + amt,
      B = (num & 0x0000ff) + amt;
    return `#${(
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
      .toUpperCase()}`;
  };

  const determineAndAdjustShade = (hex) => {
    return isLightColor(hex)
      ? adjustColorShade(hex, -20)
      : adjustColorShade(hex, 20);
  };

  useEffect(() => {
    // Function to check if the color is light or dark
    const isLightColor = (hex) => {
      // Convert hex to RGB
      const rgb = parseInt(hex?.substring(1), 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;
      // Calculate luminance
      const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
      // Return true if luminance is bright, false if dark
      return luminance > 128;
    };

    // Determine text color based on the brightness of the button color
    if (isLightColor(theme?.theme?.button?.button_color)) {
      setBtnTextColor("#000000"); // Set black text for light colors
    } else {
      setBtnTextColor("#ffffff"); // Set white text for dark colors
    }
  }, [theme?.theme?.button?.button_color]);

  const configureTheme = ({ fonts, color, theme }) => {
    // console.log("themeeeeee", theme);
    return createTheme({
      ...defaultThemeConfig,
      typography: {
        htmlFontSize: 16,
        fontFamily: "Poppins-Regular, sans-serif",
        poppins: fonts.poppins,
        rockwell: fonts.rockwell,
        ...fonts.fontWeights,
      },
      palette: {
        primary: {
          main:
            localStorage.getItem("themecolor") != null
              ? localStorage.getItem("themecolor")
              : color?.primary
              ? color?.primary
              : "#14213d",
          // main: "#a2d2ff",

          // main: "#9C0A35",
        },
        secondary: {
          main: color.secondary,
          // main: "#000000"
        },
        // background: color.background,

        common: {
          background: theme?.background_color || "#F0F5F5",
          themeFont: setFontColorOnBg(theme?.background_color || "#F0F5F5"),
          white: "#131719",
          lighterBg: "#1E2427",
          fontTitle: "#ffffff",
          normalTitle: "#000000",
          black: "#fff",
          subTitle: "#000000",
          scrollBar: "#f5f5f5",
          scrollBarIdle: "#888",
          whiteTitle: "#FFFFFF",
          headerText: "000",
          red: color.red,
          green: color.green,
          grey: "#414A50",
          lightGrey: "#c6c6c6",
        },
        button: {
          buttonColor: theme?.button?.button_color || "#000000",
          buttonFontColor: btnTextColor,
        },
        card: {
          cardBorderColor: theme?.card?.card_border_color || "#000000",
          cardColor: hexToRgba(
            convertRgbaToHex(theme?.card?.card_background_color || "#ffffff"),
            theme?.card?.card_transpirancy || 1
          ),
          cardFontColor: setFontColorOnBg(
            convertRgbaToHex(theme?.card?.card_background_color || "#ffffff")
          ),
          // cardFontColor: "#ffffff",
          cardShadeColor: determineAndAdjustShade(
            convertRgbaToHex(theme?.card?.card_background_color || "#ffffff")
          ),

          cardShadeFontColor: setFontColorOnBg(
            determineAndAdjustShade(
              convertRgbaToHex(theme?.card?.card_background_color || "#ffffff")
            )
          ),
        },
      },
    });
  };

  useEffect(() => {
    dispatch(getTheme());
  }, [dispatch]);

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={configureTheme(theme)}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default ThemeApp;
