import { call, put, select, takeLatest } from "redux-saga/effects";
import * as ACTIONS from "./TableActions";
import * as TYPES from "./TableTypes";
import { getTableBookingApi, getTableOrder } from "./TableApi";
import moment from "moment";
import * as ACTION from "../SnackBar/SnackBarAction";
import * as THEME_ACTIONS from "../Theme/ThemeActions";

export function* sagasTableRequest({ payload }) {
  try {
    const hotelKey = payload;

    // Set the loading state to true
    yield put(ACTIONS.setTableRequestPending(true));

    if (!hotelKey) {
      throw new Error("Something went wrong");
    }

    const response = yield call(getTableOrder, hotelKey);
    // console.log("response", response);
    if (!response.status) {
      throw new Error("Something went wrong");
    }

    // Dispatch your success action
    yield put(THEME_ACTIONS.getThemeNew(response?.data?.theme?.online_theme));
    yield put(ACTIONS.getTableReceive(response.data));
    yield put(ACTIONS.setTableRequestPending(false));
  } catch (err) {
    console.log("error", err);
  }
}

export function* sagaTableBooking(action) {
  try {
    // Make the API call
    const { payload } = action;

    // Perform any necessary data transformations, such as formatting the date

    const { selectedDate, startTime, endTime, peopleCount } = yield select(
      (state) => state.user
    );
    const [day, month, year] = selectedDate.split("-");

    // Create a new formatted date string in "month-day-year" format
    const formattedDate = `${month}-${day}-${year}`;

    // Construct the modified payload
    const modifiedPayload = {
      ...payload,
      date: selectedDate && selectedDate,
      time: startTime && startTime,
      reservation_from:
        startTime && new Date(`${formattedDate} ${startTime}`).getTime(),
      reservation_upto:
        endTime &&
        new Date(`${formattedDate} ${endTime}`).getTime() - 60 * 1000,
      person: peopleCount && peopleCount,
    };
    const response = yield call(getTableBookingApi, modifiedPayload);

    // Dispatch a success action with the response data
    yield put(ACTIONS.submitFormSuccess(response?.data));
  } catch (error) {
    // Dispatch a failure action with the error
    yield put(ACTION.showSnackbarAction("Something went wrong", "warning"));
    yield put(ACTIONS.submitFormFailure(error));
  }
}

export function* TableSaga() {
  yield takeLatest(TYPES.GET_TABLE_ORDER_REQUEST, sagasTableRequest);
}

export function* TableBookingSaga() {
  yield takeLatest(TYPES.TABLE_BOOKING_REQUEST, sagaTableBooking);
}
