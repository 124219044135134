import * as types from "./ThemeType";

const INITIAL_STATE = {
  // font: "Poppins-Regular",
  theme: null,
  fonts: {
    poppins: {
      fontFamily: ["Poppins-Regular"],
    },
    rockwell: {
      fontFamily: ["Rockwell"],
    },
    fontWeights: {
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
  },
  color: {
    primary: "#091014",
    secondary: "#414A50",
    red: "#9C0A35",
    green: "#19775B",
    white: "#FFFFFF",
    background: "#F0F5F5",
  },
};

// Replace with you own reducer
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_THEME_RECEIVE:
      return {
        ...state,
        // ...action.payload,
        color: {
          ...state.color,
          primary: action.payload[0]?.online_theme_color || "#091014",
        },
      };
    case types.GET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
