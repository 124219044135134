export const lightenColor = (color, amount) => {
  const hex = color.replace("#", "");
  const num = parseInt(hex, 16);
  const r = Math.min(255, (num >> 16) + amount);
  const g = Math.min(255, ((num >> 8) & 0x00ff) + amount);
  const b = Math.min(255, (num & 0x0000ff) + amount);
  return `rgb(${r}, ${g}, ${b})`;
};

export const setFontColorOnBg = (bg, hel) => {
  const hexToRgb = (hex) => {
    // Remove '#' if present
    hex = hex.replace("#", "");
    // Convert to RGB
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgb(${r}, ${g}, ${b})`;
  };

  // Convert hexadecimal color to RGB format if needed
  const color = bg?.startsWith("#") ? hexToRgb(bg) : bg;

  // Function to determine brightness of color
  const calculateBrightness = (color) => {
    // Check if color is null or not in expected format
    if (!color || !color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)) {
      return null;
    }

    // Convert color to RGB format
    const rgb = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    const r = parseInt(rgb[1]);
    const g = parseInt(rgb[2]);
    const b = parseInt(rgb[3]);
    // Calculate brightness using HSP formula
    const brightness = Math.sqrt(
      0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)
    );
    return brightness;
  };

  // Determine brightness of background color
  const brightness = calculateBrightness(color);

  // If brightness calculation fails, return null
  if (brightness === null) {
    return null;
  }
  // If background is light, return black, otherwise return white
  return brightness > 127 ? "black" : "white";
};

export function hexToRgba(hex, alpha) {
  const bigint = parseInt(hex?.replace("#", ""), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
