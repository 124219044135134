import * as types from "./TableTypes";

export const getTable = (payload) => ({
  type: types.GET_TABLE_ORDER_REQUEST,
  payload,
});

export const getTableReceive = (payload) => ({
  type: types.GET_TABLE_ORDER_RECEIVE,
  payload,
});

export const setTableRequestPending = (isPending) => ({
  type: types.SET_TABLE_REQUEST_PENDING,
  payload: isPending,
});

export const submitFormRequest = (formData) => ({
  type: types.TABLE_BOOKING_REQUEST,
  payload: formData,
});

export const submitFormSuccess = (data) => ({
  type: types.TABLE_BOOKING_SUCCESS,
  payload: data,
});

export const submitFormFailure = (error) => ({
  type: types.TABLE_BOOKING_FAILURE,
  payload: error,
});

export const clearTableBooking = () => ({
  type: types.CLEAR_TABLE_BOOKING,
});

export const setCustomTheme = (payload) => ({
  type: types.SET_CUSTOM_THEME,
  payload: payload,
});

export const setValidationCompleted = (payload) => ({
  type: types.SET_VALIDATION_COMPLETED,
  payload: payload,
});
