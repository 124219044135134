export const SET_RESTAURANT_KEY = "SET_RESTAURANT_KEY";
export const GET_TABLE_ORDER_REQUEST = "GET_RESTAURANT_REQUEST";
export const GET_TABLE_ORDER_RECEIVE = "GET_RESTAURANT_RECEIVE";
export const CLEAR_RESTURANT = "CLEAR_RESTURANT";
export const SET_APP_VERSION = "SET_APP_VERSION";
export const SET_TABLE_REQUEST_PENDING = "SET_TABLE_REQUEST_PENDING";
export const TABLE_BOOKING_REQUEST = "TABLE_BOOKING_REQUEST";
export const TABLE_BOOKING_SUCCESS = "TABLE_BOOKING_SUCCESS";
export const TABLE_BOOKING_FAILURE = "TABLE_BOOKING_FAILURE";
export const CLEAR_TABLE_BOOKING = "CLEAR_TABLE_BOOKING";
export const SET_CUSTOM_THEME = "SET_CUSTOM_THEME";
export const SET_VALIDATION_COMPLETED = "SET_VALIDATION_COMPLETED";
